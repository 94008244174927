.popup {
  width: 500px;
  height: 300px;
  overflow: scroll;
  /* display: flex; */
  /* align-items: center; */
}

.block {
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
  transition: all 0.1s ease-in-out;
  user-select: none;
}

.block:hover {
  transform: scale(1.05);
}

.blocksConatiner {
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  flex-flow: row wrap;
  padding: 0 10px;
  /* justify-content: space-between; */
}
