/* --------- ADD NODE -------------- */

.extra-node-style {
  width: 266px;
  height: 96px;
  display: flex;
  justify-content: center;
}

.extra-node-content {
  width: 220px;
  display: flex;
  padding: 10px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1.5px dashed var(--primary-default, #6c3dd1);
  background: var(--primary-light, #f5eefd);
}

/*---------------------------------------*/
.source-handle-style {
  min-height: '10px';
  min-width: '10px';
  border-radius: '0 0 50% 50%';
  background-color: '#E8E8E8';
}

.target-handle-style {
  height: '10px';
  width: '10px';
  border-radius: '50% 50% 0 0';
  background-color: '#E8E8E8';
}

.source-handle-style:hover,
.target-handle-style:hover {
  background-color: '#6C3DD1';
}

.handle-style {
  background-color: #e8e8e8;
  height: 10px;
  width: 10px;
  z-index: -1;
}

.handle-style:hover {
  background-color: '#6C3DD1';
}
