body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.monaco-editor,
.monaco-editor .overflow-guard {
  border-radius: 6px;
}

div:has(> .editor-line-readonly) {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
  z-index: 999; /* Moves this div above the code line making it not selectable */
}
